<template>
    <SmartForm base_url="/document/category" base_path="/document/category" form_type="view"></SmartForm>
</template>

<script>
import SmartForm from "@/view/components/SmartForm.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
    components: {
        SmartForm,
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Documenti", route: "/document" },
            { title: "Categorie", route: "/document/category/index" },
            { title: "Dettagli Categoria" }
        ]);
    }
};
</script>
